@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  color: beige;
}

body {
  font-family: "Roboto Slab", "Finlandica", sans-serif, serif;
}

Canvas {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

.main {
  width: calc(100% - 4em);
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  font-size: 7vmin;
  line-height: 0.75;
  letter-spacing: 0.025em;
  width: 16em;
}

.presentation,
.portfolio,
.contact,
footer {
  position: absolute;
  top: 25vh;
  margin-left: 0.25em;
}

.portfolio {
  top: 120vh;
}

.contact {
  top: 315vh;
}

footer {
  top: 390vh;
}

/* PHONE */

@media screen and (max-width: 650px) {
  .presentation {
    top: 10vh;
  }

  .portfolio {
    top: 130vh;
  }

  .contact {
    top: 330vh;
  }
}
